import { FC, ReactNode } from 'react';
import theme, { ThemeColor } from './theme';

interface Props {
  borderColor?: string;
  color: ThemeColor;
  children?: ReactNode;
  height: string;
  onClick: () => void;
  radius: string;
  width: string;
}

const Button: FC<Props> = ({
  borderColor,
  color,
  children,
  height,
  onClick,
  radius,
  width,
}) => {
  return (
    <button
      onClick={onClick}
      style={{
        cursor: 'pointer',
        borderStyle: 'solid',
        borderWidth: 1,
        backgroundColor: theme.colors[color],
        borderColor,
        borderRadius: radius,
        height,
        width,
      }}>
      {children}
    </button>
  );
};

export default Button;
