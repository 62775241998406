import AT from 'src/constants/action-types';
import { Service } from 'src/@types/common';
import { produce } from 'immer';

export interface DataReducerState {
  businessServices: Service[];
  customerDataUiEditMode: boolean;
}
const initialState: DataReducerState = {
  businessServices: [],
  customerDataUiEditMode: false,
};

export type Actions =
  | {
      type: 'GET_BUSINESS_SERVICES_SUCCESS';
      payload?: AT['GET_BUSINESS_SERVICES_SUCCESS'];
    }
  | {
      type: 'GET_BUSINESS_SERVICES_FAILED';
      payload?: AT['GET_BUSINESS_SERVICES_FAILED'];
    }
  | {
      type: 'SET_CUSTOMER_DATA_UI_EDIT_MODE';
      payload?: AT['SET_CUSTOMER_DATA_UI_EDIT_MODE'];
    };

export default function reducer(
  state: DataReducerState = initialState,
  action: Actions,
): DataReducerState {
  const { type, payload } = action;

  switch (type) {
    case 'GET_BUSINESS_SERVICES_SUCCESS': {
      const services = payload as AT[typeof type];

      return produce(state, (draft) => {
        draft.businessServices = services;
      });
    }
    case 'SET_CUSTOMER_DATA_UI_EDIT_MODE': {
      const isEditMode = payload as AT[typeof type];

      return produce(state, (draft) => {
        draft.customerDataUiEditMode = isEditMode;
      });
    }
    default:
      break;
  }

  return state;
}
