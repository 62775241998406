import { useEffect } from 'react';
import Modal from 'react-modal'; // It can be any modal
import { SCREENS } from 'src/constants/strings';
import { Box, Text } from 'src/theme';
import saveIcon from 'src/images/notice_modal/Icon.svg';
import { MODALS, MODAL_TYPES } from 'src/@types/navigation';
import { useMediaQuery } from 'react-responsive';
import { mobileMaxWidth } from 'src/utils/responsiveSize';

export default function NoticeModal({
  isOpen,
  onClose,
  title = SCREENS.NOTICE_MODAL_TITLE,
  subTitle = SCREENS.NOTICE_MODAL_SUB_TITLE,
  timeout = 2,
  contentStyle,
  timeoutCallBack,
}: MODALS[MODAL_TYPES.NOTICE]): JSX.Element {
  const isMobile = useMediaQuery({ query: mobileMaxWidth });

  useEffect(() => {
    const timer = setTimeout(() => {
      timeoutCallBack && timeoutCallBack();
      onClose();
    }, timeout * 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Modal
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: 1000,
          backgroundColor: '#3737372E',
        },
        content: contentStyle,
      }}
      isOpen={isOpen}
      onRequestClose={onClose}>
      <Box textAlign={'center'} px={isMobile ? 10 : 80} py={20}>
        <Box p={10}>
          <img src={saveIcon} alt="saveIcon" />
        </Box>
        <Text fontSize={18}>{title}</Text>
        <Text color={'grey7a7a7a'}>{subTitle}</Text>
      </Box>
    </Modal>
  );
}
