import { ThunkDispatch } from 'redux-thunk';
import { Middleware } from 'redux';
import AT from 'src/constants/action-types';
import { Action } from 'src/reducers/index';
import { State } from '.';
import get from '../cache/index';

export type CacheMiddlewareActions = {
  type: 'INVALIDATE_CACHE';
  payload: AT['INVALIDATE_CACHE'];
};
const cacheMiddleware: Middleware<
  unknown,
  State,
  ThunkDispatch<State, unknown, Action>
> = () => (next) => (action: Action) => {
  next(action);
  const { type, payload } = action;

  switch (type) {
    case 'INVALIDATE_CACHE': {
      const $payload = payload as AT[typeof type];
      const queryClient = get();
      $payload.map((key) => {
        queryClient.invalidateQueries(key);
      });
    }
  }
};
export default cacheMiddleware;
