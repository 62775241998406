import Modal from 'react-modal'; // It can be any modal
import { MODALS, MODAL_TYPES } from 'src/@types/navigation';
import { SCREENS } from 'src/constants/strings';
import theme, { Box, Text } from 'src/theme';
import { useQuery, useMutation } from 'react-query';
import { ROUTES } from 'src/api/Routes';
import { fetchNotifications } from 'src/screens/MainContent/UserSettings/NotificationsTab/api';
import { Grid } from '@mui/material';
import { sendNotification } from '../screens/MainContent/AddCustomer/api';
import { useMediaQuery } from 'react-responsive';
import { mobileMaxWidth } from 'src/utils/responsiveSize';
// import { getWhatsAppState } from './api';
// import { WhatsAppState } from 'src/components/WhatsAppConnector/api';
const extractTextFromHtml = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};
export default function SendAlertModal({
  isOpen,
  onClose,
  contentStyle,
  customersIds,
  phoneNumber,
}: MODALS[MODAL_TYPES.SEND_ALERT]): JSX.Element {
  const isMobile = useMediaQuery({ query: mobileMaxWidth });

  const { data } = useQuery([ROUTES.GET.GET_NOTIFICATIONS], fetchNotifications);
  // const whatsappQuery = useQuery<WhatsAppState>(
  //   [ROUTES.GET.GET_WHATSAPP_STATE],
  //   getWhatsAppState,
  // );

  const sentNotificationMutation = useMutation(sendNotification, {
    onSuccess: () => {
      onClose();
    },
  });
  if (!data) {
    return <Box></Box>;
  }

  return (
    <Modal
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: 1000,
          backgroundColor: '#3737372E',
        },
        content: {
          ...contentStyle,
          padding: 0,
        },
      }}
      isOpen={isOpen}
      onRequestClose={onClose}>
      <Box textAlign={'center'}>
        {/* <Text fontSize={18}>{title}</Text> */}
        <Grid
          container
          sx={{
            borderRadius: 2,
            borderColor: theme.colors.greye9ecef,
            borderStyle: 'solid',
          }}>
          <Grid
            item
            md={12}
            sx={{
              borderBlockColor: theme.colors.greye9ecef,
              borderBlockStyle: 'solid',
              backgroundColor: theme.colors.greyf8f9fa,
              flex: 1,
              py: 2,
              px: 3,
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Text fontSize={12} color="grey686868">
              {SCREENS.CREATE_NOTIFICATION_TABLE_TITLE}
            </Text>
          </Grid>

          {data.map((notification) => {
            const whatsAppMessage = extractTextFromHtml(
              notification.whatsappMessage,
            );
            let filesListString = '';
            notification.files.map((file) => {
              filesListString += `\n${file.file}`;
            });

            return (
              <Grid
                item
                md={12}
                sx={{
                  flex: 1,
                  borderBottomColor: theme.colors.greye9ecef,
                  borderBottomStyle: 'solid',
                  py: 2,
                  px: 3,
                  alignItems: 'center',
                  display: 'flex',
                }}>
                <Box
                  flex={1}
                  justifyContent="space-between"
                  display="flex"
                  alignItems="center">
                  <Box flex={6}>
                    <Text fontSize={isMobile ? 12 : 16} textAlign="start">
                      {notification.notificationName}
                    </Text>
                  </Box>
                  <Box display="flex" flex={1}>
                    <Box pl={2}>
                      <button
                        onClick={() => {
                          customersIds.map((customerId) => {
                            sentNotificationMutation.mutate({
                              customerId,
                              notificationId: notification.id,
                              notificationType: 'email',
                            });
                          });
                        }}
                        style={{
                          backgroundColor: theme.colors.blue6ea2ff,
                          borderColor: theme.colors.blue6ea2ff,
                          color: theme.colors.white,
                          borderStyle: 'solid',
                          width: isMobile ? 50 : 110,
                          height: 36,
                          borderRadius: 10,
                          justifyContent: 'center',
                          cursor: 'pointer',
                          alignSelf: 'center',
                          fontSize: isMobile ? 10 : 12,
                        }}>
                        {SCREENS.SEND_NOTIFICATION_EMAIL}
                      </button>
                    </Box>
                    {phoneNumber && (
                      <Box>
                        <button
                          onClick={() => {
                            const url = `https:///wa.me/972${phoneNumber.replaceAll(
                              '-',
                              '',
                            )}?text=${encodeURIComponent(
                              whatsAppMessage + filesListString,
                            )}`;
                            // const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
                            //   whatsAppMessage,
                            // )}`;
                            window.open(url, '_blank', 'noopener,noreferrer');
                          }}
                          style={{
                            backgroundColor: theme.colors.blue6ea2ff,
                            borderColor: theme.colors.blue6ea2ff,
                            color: theme.colors.white,
                            borderStyle: 'solid',
                            width: isMobile ? 50 : 110,
                            height: 36,
                            borderRadius: 10,
                            justifyContent: 'center',
                            cursor: 'pointer',
                            alignSelf: 'center',
                            fontSize: isMobile ? 10 : 12,
                          }}>
                          {SCREENS.SEND_NOTIFICATION_WHATSAPP}
                        </button>
                      </Box>
                    )}

                    {/* {whatsappQuery.data && whatsappQuery.data === 'CONNECTED' && (
                      <Box>
                        <button
                          onClick={() => {
                            customersIds.map((customerId) => {
                              sentNotificationMutation.mutate({
                                customerId,
                                notificationId: notification.id,
                                notificationType: 'whatsapp',
                              });
                            });
                          }}
                          style={{
                            backgroundColor: theme.colors.blue6ea2ff,
                            borderColor: theme.colors.blue6ea2ff,
                            color: theme.colors.white,
                            borderStyle: 'solid',
                            width: isMobile ? 50 : 110,
                            height: 36,
                            borderRadius: 10,
                            justifyContent: 'center',
                            cursor: 'pointer',
                            alignSelf: 'center',
                            fontSize: isMobile ? 10 : 12,
                          }}>
                          {SCREENS.SEND_NOTIFICATION_WHATSAPP}
                        </button>
                      </Box>
                    )} */}
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Modal>
  );
}
