import { MODALS, MODAL_TYPES } from 'src/@types/navigation.d';
import NoticeModal from './NoticeModal';
import ErrorModal from './ErrorModal';
import TermsModal from './TermsModal';
import SignatureModal from './SignatureModal';
import PatternsModal from './PatternsModal';
import ContractPreviewModal from './ContractPreviewModal';
import LoaderModal from './LoaderModal';
import SignatureModalWithId from './SignatureModalWithId';
import UploadFileModal from './UploadFileModal';
import { useMediaQuery } from 'react-responsive';
import { mobileMaxWidth } from 'src/utils/responsiveSize';
import ApprovalModal from './ApprovalModal';
import OptionsModal from './OptionsModal';
import SendAlertModal from './SendAlertModal';
type ModalProps = {
  isOpen: boolean;
  onClose?: () => boolean;
  type: MODAL_TYPES;
  params: MODALS[MODAL_TYPES];
};

const Modal = ({ type, params, isOpen, onClose }: ModalProps): JSX.Element => {
  const isMobile = useMediaQuery({ query: mobileMaxWidth });

  const contentStyle = {
    boxShadow: '0px 1px 6px 0px rgba(67, 86, 100, 0.12)',
    borderWidth: '0px',
    borderRadius: '9px',
    top: '50%',
    left: isMobile ? '50%' : '45%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    paddingX: isMobile ? 20 : 80,
    paddingY: 20,
  };

  switch (type) {
    case MODAL_TYPES.NOTICE: {
      const props = params as MODALS[typeof type];
      return (
        <NoticeModal
          contentStyle={contentStyle}
          {...props}
          isOpen={isOpen}
          onClose={onClose}
        />
      );
    }
    case MODAL_TYPES.ERROR: {
      const props = params as MODALS[typeof type];
      return (
        <ErrorModal
          contentStyle={contentStyle}
          {...props}
          isOpen={isOpen}
          onClose={onClose}
        />
      );
    }
    case MODAL_TYPES.TERMS: {
      const props = params as MODALS[typeof type];
      return (
        <TermsModal
          contentStyle={contentStyle}
          {...props}
          isOpen={isOpen}
          onClose={onClose}
        />
      );
    }
    case MODAL_TYPES.SIGNATURE: {
      const props = params as MODALS[typeof type];
      return props.isWithId ? (
        <SignatureModalWithId
          contentStyle={contentStyle}
          {...props}
          isOpen={isOpen}
          onClose={onClose}
        />
      ) : (
        <SignatureModal
          contentStyle={{
            ...contentStyle,
            borderWidth: '2px',
            borderColor: 'grey',
          }}
          {...props}
          isOpen={isOpen}
          onClose={onClose}
        />
      );
    }
    case MODAL_TYPES.PATTERNS: {
      const props = params as MODALS[typeof type];
      return (
        <PatternsModal
          contentStyle={contentStyle}
          {...props}
          isOpen={isOpen}
          onClose={onClose}
        />
      );
    }
    case MODAL_TYPES.CONTRACT_PREVIEW: {
      const props = params as MODALS[typeof type];
      return (
        <ContractPreviewModal
          contentStyle={contentStyle}
          {...props}
          isOpen={isOpen}
          onClose={onClose}
        />
      );
    }
    case MODAL_TYPES.LOADER: {
      const props = params as MODALS[typeof type];
      return (
        <LoaderModal
          contentStyle={contentStyle}
          {...props}
          isOpen={isOpen}
          onClose={onClose}
        />
      );
    }
    case MODAL_TYPES.UPLOAD_FILE: {
      const props = params as MODALS[typeof type];
      return (
        <UploadFileModal
          contentStyle={{ ...contentStyle, left: '50%' }}
          {...props}
          isOpen={isOpen}
          onClose={onClose}
        />
      );
    }
    case MODAL_TYPES.APPROVAL: {
      const props = params as MODALS[typeof type];
      return (
        <ApprovalModal
          contentStyle={{ ...contentStyle, left: '50%' }}
          {...props}
          isOpen={isOpen}
          onClose={onClose}
        />
      );
    }
    case MODAL_TYPES.OPTIONS: {
      const props = params as MODALS[typeof type];
      return (
        <OptionsModal
          contentStyle={{ ...contentStyle }}
          {...props}
          isOpen={isOpen}
          onClose={onClose}
        />
      );
    }
    case MODAL_TYPES.SEND_ALERT: {
      const props = params as MODALS[typeof type];
      return (
        <SendAlertModal
          contentStyle={{ ...contentStyle }}
          {...props}
          isOpen={isOpen}
          onClose={onClose}
        />
      );
    }
  }
};

export default Modal;
