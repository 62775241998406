import AT from 'src/constants/action-types';
import { CustomerItem } from 'src/@types/shared';
import { produce } from 'immer';

export interface CustomersReducerState {
  customerList: CustomerItem[];
}
const initialState: CustomersReducerState = {
  customerList: [],
};

export type Actions =
  | {
      type: 'SET_CUSTOMER_LIST';
      payload: AT['SET_CUSTOMER_LIST'];
    }
  | { type: 'UPDATE_CUSTOMER'; payload: AT['UPDATE_CUSTOMER'] };

export default function reducer(
  state: CustomersReducerState = initialState,
  action: Actions,
): CustomersReducerState {
  const { type, payload } = action;

  switch (type) {
    case 'SET_CUSTOMER_LIST': {
      const customerList = payload as AT[typeof type];

      return produce(state, (draft) => {
        draft.customerList = [];
        draft.customerList.push({
          firstName: 'שם',
          lastName: 'הלקוח',
          phoneNumber: 'מספר טלפון',
          email: 'כתובת אימייל',
          events: 'מספר שירותים ללקוח',
          id: -1,
          isChecked: false,
        });
        draft.customerList.push(...customerList);
      });
    }
    case 'UPDATE_CUSTOMER': {
      const customer = payload as AT[typeof type];

      return produce(state, (draft) => {
        const updateList = draft.customerList.map((updateCustomer) => {
          if (updateCustomer.id === customer.id) {
            return customer;
          }
          return updateCustomer;
        });
        draft.customerList = updateList;
      });
    }
    default:
      break;
  }

  return state;
}
