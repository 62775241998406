import { useCallback } from 'react';
import rootReducer from 'src/reducers';
import { Action } from './../reducers/index';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import {
  applyMiddleware,
  compose as reduxCompose,
  createStore,
  Middleware,
  Store,
  StoreEnhancer,
  PreloadedState,
} from 'redux';
import { State } from 'src/middlewares';
import fetchMiddleware from 'src/middlewares/fetchMiddleware';
import { useDispatch as _useDispatch } from 'react-redux';
import cacheMiddleware from 'src/middlewares/cacheMiddleware';

function getCompose(): (
  x?: ReturnType<typeof applyMiddleware>,
) => StoreEnhancer {
  return reduxCompose;
}
function getMiddlewareList(): Middleware<
  unknown,
  State,
  ThunkDispatch<State, unknown, Action>
>[] {
  const defaults = [thunkMiddleware, fetchMiddleware, cacheMiddleware];
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { createLogger } = require('redux-logger');
    const logger = createLogger({
      collapsed: true,
      predicate: (_, { type }) => type !== undefined,
    });

    return [logger, ...defaults];
  }

  return defaults;
}

function configureStore(preloadedState?: PreloadedState<State>): Store<State> {
  const middlewareList = getMiddlewareList();
  const middlewareEnhancer = applyMiddleware(...middlewareList);
  const enhancers = [middlewareEnhancer];
  const compose = getCompose();
  const composedEnhancers = compose(...enhancers);
  const store = createStore(
    rootReducer,
    // localStorage.getItem('state')
    // ? JSON.parse(localStorage.getItem('state'))
    // :
    preloadedState,
    composedEnhancers,
  );
  store.subscribe(() => {
    localStorage.setItem('uiState', JSON.stringify(store.getState().user));
  });
  return store;
}

export function useDispatch(): (event: Action) => void {
  const dispatch = _useDispatch();
  const fn = useCallback(
    (event: Action) => {
      dispatch(event);
    },
    [dispatch],
  );

  return fn;
}

export default configureStore;
