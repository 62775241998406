import { CSSProperties } from 'react';
import styled from 'styled-components';
import {
  BackgroundImageProps,
  BordersProps,
  ColorProps,
  FlexboxProps,
  GridProps,
  JustifyItemsProps,
  LayoutProps,
  LineHeightProps,
  PositionProps,
  SpaceProps,
  TextAlignProps,
  Theme,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
} from 'styled-system';
import { ThemeColor } from './theme';

export type StyledSystemBoxProps = ColorProps &
  SpaceProps &
  LayoutProps &
  Theme &
  TextAlignProps &
  BackgroundImageProps &
  FlexboxProps &
  BordersProps &
  LineHeightProps &
  PositionProps &
  GridProps &
  JustifyItemsProps;

type BoxProps = StyledSystemBoxProps & BoxStrictType;
type BoxStrictType = {
  style?: CSSProperties;
  backgroundColor?: ThemeColor;
  borderColor?: ThemeColor;
};
const Box = styled.div.attrs((props: BoxProps) => ({
  style: props.style,
}))<BoxProps>`
  ${space};
  ${layout};
  ${color};
  ${border};
  ${typography};
  ${flexbox};
  ${grid};
  ${position};
  ${shadow};
`;

export default Box;
