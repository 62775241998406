const theme = {
  colors: {
    mainBackground: '#ffffff',
    loginColor: '#2a5c99',
    googleColor: '#fc5a5a',
    facebookColor: '#0062ff',
    inputBorder: '#8392a7',
    inputPlaceHolder: '#373737',
    transparent: '#00000000',
    //white
    white: '#ffffff',
    whiteF2F2F2: '#F2F2F2',
    whiteDFEDF2: '#DFEDF2',
    whiteFAFBFD: '#FAFBFD',
    whitef0f0f0: '#f0f0f0',
    whiteWithTransperent: '#ffffffE6',
    //black
    blackText: '#171725',
    black323c47: '#323c47',
    //grey
    greyf9f9f9: '#f9f9f9',
    paleGrey: '#f9fafb',
    greya5a5a5: '#a5a5a5',
    grey7a7a7a: '#7a7a7a',
    greyE3E3E3: '#E3E3E3',
    greye2e2ea: '#e2e2ea',
    greye9ecef: '#e9ecef',
    grey979797: '#979797',
    greyA098AE: '#A098AE',
    lightGrey: 'rgba(205, 205, 212, 0.1)',
    greyPlaceHolder: '#898e96',
    greyBorder: '#bababa',
    greyb5b5be: '#b5b5be',
    greydadada: '#dadada',
    grey989898: '#989898',
    greyf0f3f7: '#f0f3f7',
    greyfafafb: '#fafafb',
    greye4e7eb: '#e4e7eb',
    greyf8f9fa: '#f8f9fa',
    grey778699: '#778699',
    grey77838f: '#77838f',
    greyfff: '#fff',
    greydce1e7: '#dce1e7',
    separator: 'rgba(63, 63, 68, 0.15)',
    grey3A3B3F: '#3A3B3F',
    grey9EA0A5: '#9EA0A5',
    grey6D7278: '#6D7278',
    grey686868: '#686868',
    greyD9D9D9: '#D9D9D9',
    greyCCCCCC: '#CCCCCC',

    //blue
    blue2c3f58: '#2c3f58',
    blue4da1ff: '#4da1ff',
    blue59bdbd: '#59bdbd',
    lightBlue: '#50b5ff',
    blueECF2FE: '#ECF2FE',
    lightBluef3f7ff: '#f3f7ff',
    lightBluedfeaff: '#dfeaff',
    blue6ea2ff: '#6ea2ff',
    blueA5C5FF33: '#A5C5FF33',
    blueD1E1FF: '#D1E1FF',
    blueLight: 'rgba(110, 162, 255, 0.2)',
    bluePurple: 'rgba(110, 162, 255, 0.15)',
    blue2A5C99: '#2A5C99',
    blue0F70EB: '#0F70EB',
    //red
    redError: '#cc0000',
    redEC4C47: '#EC4C47',
    redFC5A5A: '#FC5A5A',
    //pink
    pinkff578f: '#ff578f',
    //yellow
    yelloweac43d: '#eac43d',
    yellowffb946: '#ffb946',
    yellowEAC43D: '#EAC43D',
    yellowFFF5E4: '#FFF5E4',
    //orange
    orangeF7685B: '#F7685B',
    orangeE69138: '#e69138',
    //green
    green00D0AC: '#00D0AC',
    green3cb043: '#3cb043',
    greenLight: '#A6CD4E',
    green8FC813: '#8FC813',
    greenPastel: '#77DD77',
    green3ACA60: '#3ACA60',
    green79C628: '#79C628',
    //purple
    purple6EA2FFD9: '#6EA2FFD9',
    purple696CFF: '#696CFF',
    purple717CFF: '#717CFF',
  },
  textVariants: {},
};

export type Theme = typeof theme;
export type ThemeColor = keyof typeof theme.colors;
export type ThemeVariant = keyof typeof theme.textVariants;

export default theme;
