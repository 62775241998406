import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

function get(): QueryClient {
  return queryClient;
}

export default get;
