import { combineReducers } from 'redux';
import userReducer, { Actions as UserActions } from './userReducer';
import uiReducer, { Actions as UiActions } from './uiReducer';
import dataReducer, { Actions as DataActions } from './dataReducer';
import customersReducer, {
  Actions as CustomersActions,
} from './customersReducer';
import contractReducer, { Actions as ContractActions } from './contractReducer';
import { FetchMiddlewareActions } from 'src/middlewares/fetchMiddleware';
import { CacheMiddlewareActions } from 'src/middlewares/cacheMiddleware';

const rootReducer = combineReducers({
  user: userReducer,
  ui: uiReducer,
  data: dataReducer,
  contract: contractReducer,
  customers: customersReducer,
});

export type Action =
  | UserActions
  | UiActions
  | DataActions
  | ContractActions
  | CustomersActions
  | FetchMiddlewareActions
  | CacheMiddlewareActions;

export default rootReducer;
