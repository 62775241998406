import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box } from 'src/theme';
import theme from 'src/theme/theme';
import { mobileMaxWidth } from 'src/utils/responsiveSize';

interface PDFViewerProps {
  pdfPages: { base64: string }[];
  isSent?: boolean;
}

const MyPdfView: React.FC<PDFViewerProps> = ({ pdfPages, isSent }) => {
  const isMobile = useMediaQuery({ query: mobileMaxWidth });
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <Box flex={1}>
      <List>
        {pdfPages &&
          pdfPages.map((image, index) => {
            return (
              <ListItem>
                <img
                  style={{
                    ...(isMobile && { width: windowSize[0] - 40 }),
                    margin: 'auto',
                    alignSelf: 'center',
                    padding: 1,
                    backgroundColor: theme.colors.whitef0f0f0,
                  }}
                  key={index}
                  src={`data:image/jpeg;base64,${image.base64}`}
                />
              </ListItem>
            );
          })}
      </List>
    </Box>
  );
};

export default MyPdfView;
