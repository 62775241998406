import { CSSProperties } from 'react';
import styled from 'styled-components';
import {
  BorderColorProps,
  BorderStyleProps,
  BorderWidthProps,
  ColorProps,
  FlexboxProps,
  FontSizeProps,
  LayoutProps,
  SpaceProps,
  TypographyProps,
  color,
  flexbox,
  layout,
  space,
  typography,
} from 'styled-system';
import { ThemeColor } from './theme';

export type StyledSystemTextProps = FontSizeProps &
  BorderWidthProps &
  BorderStyleProps &
  BorderColorProps &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  TypographyProps &
  ColorProps;
export type TextProps = StyledSystemTextProps & TextStrictType;

type TextStrictType = {
  style?: CSSProperties;
  backgroundColor?: ThemeColor;
  borderColor?: ThemeColor;
  color?: ThemeColor;
};

const Text = styled.p.attrs((props: TextProps) => ({
  style: props.style,
}))<TextProps>`
  font-size: 14px;
  color: ${'blackText'};
  ${space} ${color} ${layout}
    ${typography} ${flexbox};
`;

export default Text;
