import { Text } from 'src/theme';
import Button from 'src/theme/Button';

type Props = {
  text: string;
  type: 'transparent' | 'blue';
  onClick: () => void;
};

const ModalButton = ({ text, type, onClick }: Props): JSX.Element => {
  return (
    <Button
      height="36px"
      width={text.length > 10 ? '110px' : '80px'}
      radius="7px"
      onClick={onClick}
      color={type === 'blue' ? 'blue6ea2ff' : 'white'}
      borderColor="#6ea2ff">
      <Text fontWeight={300} color={type === 'blue' ? 'white' : 'blue6ea2ff'}>
        {text}
      </Text>
    </Button>
  );
};

export default ModalButton;
