import { ComponentType, LazyExoticComponent, Suspense } from 'react';
import { Box, Text } from 'src/theme';

// project imports

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable =
  (
    Component:
      | LazyExoticComponent<() => JSX.Element>
      | ComponentType<React.ReactNode>,
    children?: () => JSX.Element,
  ) =>
  (): JSX.Element =>
    (
      <Suspense
        fallback={
          children || (
            <Box backgroundColor={'mainBackground'}>
              <Text backgroundColor={'mainBackground'} fontSize={30}>
                Loading.....
              </Text>
            </Box>
          )
        }>
        <Component />
      </Suspense>
    );

export default Loadable;
