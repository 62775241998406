import Modal from 'react-modal'; // It can be any modal
import { MODALS, MODAL_TYPES } from 'src/@types/navigation';
import { SCREENS } from 'src/constants/strings';
import saveIcon from 'src/images/notice_modal/Icon.svg';
import { Box, Text } from 'src/theme';

export default function LoaderModal({
  isOpen,
  onClose,
  contentStyle,
  title = SCREENS.LOADER_MODAL_TITLE,
  subTitle = SCREENS.LOADER_MODAL_SUB_TITLE,
}: MODALS[MODAL_TYPES.LOADER]): JSX.Element {
  return (
    <Modal
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: 1000,
          backgroundColor: '#3737372E',
        },
        content: contentStyle,
      }}
      isOpen={isOpen}
      onRequestClose={onClose}>
      <Box textAlign={'center'} px={80} py={20}>
        <Box p={10}>
          <img src={saveIcon} alt="saveIcon" />
        </Box>
        <Text fontSize={18}>{title}</Text>
        <Text color={'grey7a7a7a'}>{subTitle}</Text>
      </Box>
    </Modal>
  );
}
