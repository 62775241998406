import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import vStatus from 'src/images/snack_bar/v_status.svg';
import { State } from 'src/middlewares';
import { useDispatch } from 'src/store';
import { Box, Text } from 'src/theme';
import { ThemeColor } from 'src/theme/theme';

export default function TopSnackBar(): JSX.Element {
  const dispatch = useDispatch();
  const topSnackBar = useSelector((state: State) => state.ui.topSnackBar);
  const initialState: {
    display: 'none' | 'flex';
    color: ThemeColor;
    title: string;
    icon: string;
  } = {
    display: 'none',
    color: 'transparent',
    title: '',
    icon: vStatus,
  };
  useEffect(() => {
    if (topSnackBar.display !== 'none') {
      setTimeout(() => {
        return dispatch({
          type: 'SHOW_TOP_SNACK_BAR',
          payload: initialState,
        });
      }, 3000);
    }
  }, [topSnackBar]);
  return (
    <Box
      mt={64}
      position="absolute"
      width="100%"
      height={40}
      justifyContent="start"
      backgroundColor={
        topSnackBar.display === 'flex' ? topSnackBar.color : initialState.color
      }
      display={
        topSnackBar.display === 'flex'
          ? topSnackBar.display
          : initialState.display
      }>
      <Box px={38} py={10} display="flex">
        <img
          style={{ alignSelf: 'center' }}
          height={30}
          width={30}
          src={initialState.icon}
        />
        <Text color={'white'} pr={10} alignSelf="center" fontSize="16px">
          {topSnackBar.display === 'flex'
            ? topSnackBar.title
            : initialState.title}
        </Text>
      </Box>
    </Box>
  );
}
