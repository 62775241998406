import { ReactNode } from 'react';
import { CustomerDetails } from 'src/api/Logic/DataLogic';

export type ModalContentStyle = {
  boxShadow: string;
  borderWidth: string;
  borderRadius: string;
  top: string;
  left: string;
  right: 'auto';
  bottom: 'auto';
  transform: 'translate(-50%, -50%)';
  paddingX: number;
  paddingY: number;
};
interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  contentStyle: ModalContentStyle;
}
interface NoticeModalProps extends ModalProps {
  title?: string;
  subTitle?: string;
  timeout?: number;
  timeoutCallBack?: () => void;
}
interface ErrorModalProps extends ModalProps {
  title?: string;
  subTitle?: string;
}
interface TermsModalProps extends ModalProps {
  title?: string;
  subTitle?: string[];
  icon: string;
  rightBtnText: string;
  leftBtnText: string;
  rightBtn: () => void;
  leftBtn: () => void;
}
interface SignatureModalProps extends ModalProps {
  title?: string;
  subTitle?: string;
  isWithId?: boolean;
  details: CustomerDetails;
  saveCallBack: ({
    img,
    details,
  }: {
    img: string;
    details: CustomerDetails;
  }) => void;
}
interface PatternsModalProps extends ModalProps {
  saveCallBack: (val: number) => void;
}
interface ContractPreviewProps extends ModalProps {
  saveCallBack: (val: string) => void;
  contract: ReactNode[];
}
interface LoaderProps extends ModalProps {
  title: string;
  subTitle: string;
  saveCallBack: (val: string) => void;
}
interface UploadFileProps extends ModalProps {
  title: string;
  subTitle: string;
  saveCallBack: (val: string) => void;
}
interface ApprovalProps extends ModalProps {
  title: string;
  subTitle: string;
  icon: string;
  rightBtnText: string;
  leftBtnText: string;
  rightBtn: () => void;
  leftBtn: () => void;
  saveCallBack: (val: string) => void;
}
interface OptionsModalProps extends ModalProps {
  title: string;
  rightBtnText: string;
  leftBtnText: string;
  rightBtn: (id: number) => void;
  leftBtn: (id: number) => void;
  options: { id: number; label: string }[];
  saveCallBack: (val: string) => void;
}
interface SendAlertModalProps extends ModalProps {
  title: string;
  customersIds: number[];
  phoneNumber?:string;
}
type MODALS = {
  [MODAL_TYPES.NOTICE]: NoticeModalProps;
  [MODAL_TYPES.ERROR]: ErrorModalProps;
  [MODAL_TYPES.TERMS]: TermsModalProps;
  [MODAL_TYPES.SIGNATURE]: SignatureModalProps;
  [MODAL_TYPES.PATTERNS]: PatternsModalProps;
  [MODAL_TYPES.CONTRACT_PREVIEW]: ContractPreviewProps;
  [MODAL_TYPES.LOADER]: LoaderProps;
  [MODAL_TYPES.UPLOAD_FILE]: UploadFileProps;
  [MODAL_TYPES.APPROVAL]: ApprovalProps;
  [MODAL_TYPES.OPTIONS]: OptionsModalProps;
  [MODAL_TYPES.SEND_ALERT]: SendAlertModalProps;
};

export enum MODAL_TYPES {
  NOTICE = 'NOTICE',
  ERROR = 'ERROR',
  TERMS = 'TERMS',
  SIGNATURE = 'SIGNATURE',
  PATTERNS = 'PATTERNS',
  CONTRACT_PREVIEW = 'CONTRACT_PREVIEW',
  LOADER = 'LOADER',
  UPLOAD_FILE = 'UPLOAD_FILE',
  APPROVAL = 'APPROVAL',
  OPTIONS = 'OPTIONS',
  SEND_ALERT = 'SEND_ALERT',
}
