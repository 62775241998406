import { useRef } from 'react';
import Modal from 'react-modal'; // It can be any modal
import SignatureCanvas from 'react-signature-canvas';
import { Box, Text } from 'src/theme';
import { MODALS, MODAL_TYPES } from 'src/@types/navigation';
import ModalButton from './ModalButton';

export default function SignatureModal({
  isOpen,
  onClose,
  title = 'נא לחתום',
  saveCallBack,
  contentStyle,
}: MODALS[MODAL_TYPES.SIGNATURE]): JSX.Element {
  const canvasRef = useRef(null);

  function onSave() {
    saveCallBack(canvasRef.current.toDataURL());
    onClose();
  }

  return (
    <Modal
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: '#3737372E',
        },
        content: contentStyle,
      }}
      isOpen={isOpen}
      onRequestClose={onClose}>
      <Box textAlign={'center'} px={40} py={10}>
        <Text fontSize={18}>{title}</Text>

        <SignatureCanvas
          ref={canvasRef}
          penColor="black"
          canvasProps={{ width: 400, height: 200, className: 'sigCanvas' }}
        />
        <Box mt={10} justifyContent="center" display="flex">
          <Box px={1}>
            <ModalButton onClick={onSave} text={'שמור'} type="transparent" />
          </Box>
          <Box px={1}>
            <ModalButton
              onClick={() => {
                if (canvasRef !== null) {
                  canvasRef.current.clear();
                }
              }}
              text={'אפס'}
              type="transparent"
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
