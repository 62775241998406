import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import TagManager from 'react-gtm-module';

// Tag Manager
let gtmid = '';
const currentURL = window.location.href;
if (currentURL.includes('localhost')) {
  gtmid = '';
} else if (currentURL.includes('app-staging')) {
  gtmid = 'GTM-T4DZZ23';
} else {
  gtmid = 'GTM-K946TSK';
}
const tagManagerArgs = {
  gtmId: gtmid,
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
