import Modal from 'react-modal'; // It can be any modal
import { useEffect } from 'react';

import { SCREENS } from 'src/constants/strings';
import { Box, Text } from 'src/theme';
import saveIcon from 'src/images/notice_modal/Icon.svg';
import { MODALS, MODAL_TYPES } from 'src/@types/navigation';
import { useSelector } from 'react-redux';
import { State } from 'src/middlewares';

export default function UploadFileModal({
  isOpen,
  onClose,
  title = SCREENS.UPLOAD_FILE_MODAL_TITLE,
  subTitle = SCREENS.UPLOAD_FILE_MODAL_SUB_TITLE,
  contentStyle,
}: MODALS[MODAL_TYPES.UPLOAD_FILE]): JSX.Element {
  const progressBarTime = useSelector(
    (state: State) => state.ui.uploadFileProgressTime,
  );
  return (
    <Modal
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: 1000,
          backgroundColor: '#3737372E',
        },
        content: contentStyle,
      }}
      isOpen={isOpen}
      // onClick={(e) => e.stopPropagation()}
      // onRequestClose={onClose}
    >
      <Box
        textAlign={'center'}
        px={contentStyle.paddingX}
        py={contentStyle.paddingY}>
        <Box p={10}>
          <img src={saveIcon} alt="saveIcon" />
        </Box>
        <Text fontSize={18}>{title}</Text>
        <Text color={'grey7a7a7a'}>{subTitle}</Text>
        {/* <Text fontSize={20} color={'blackText'}>{`${
          progressBarTime | 0
        } %`}</Text> */}
      </Box>
    </Modal>
  );
}
