const getItemFromStorage = async ({
  key,
}: {
  key: string;
}): Promise<string> => {
  return localStorage.getItem(key);
};

const setItemToStorage = async ({
  key,
  value,
}: {
  key: string;
  value: string;
}): Promise<void> => {
  localStorage.setItem(key, value);
};

// Simple XOR encryption function
const encrypt = (text: string, key: string): string => {
  let encryptedText = '';
  for (let i = 0; i < text.length; i++) {
    const charCode = text.charCodeAt(i) ^ key.charCodeAt(i % key.length);
    encryptedText += String.fromCharCode(charCode);
  }
  return encryptedText;
};

// Simple XOR decryption function
const decrypt = (encryptedText: string, key: string): string => {
  let decryptedText = '';
  for (let i = 0; i < encryptedText.length; i++) {
    const charCode =
      encryptedText.charCodeAt(i) ^ key.charCodeAt(i % key.length);
    decryptedText += String.fromCharCode(charCode);
  }
  return decryptedText;
};

export const setUserAndPasswordInLocalStorage = async ({
  user,
  password,
}: {
  user: string;
  password: string;
}): Promise<void> => {
  setItemToStorage({ key: 'user', value: `${encrypt(user, 'crd')}` });
  setItemToStorage({ key: 'pass', value: `${encrypt(password, 'crd')}` });
};
export const getUserAndPasswordFromLocalStorage = async (): Promise<{
  user: string;
  password: string;
}> => {
  const user = decrypt(
    (await getItemFromStorage({ key: 'user' })) || '',
    'crd',
  );
  const password = decrypt(
    (await getItemFromStorage({ key: 'pass' })) || '',
    'crd',
  );
  return { user, password };
};
