import { lazy } from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as MainRouter,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import fetcher from 'src/api/RequestHandler';
import { State } from 'src/middlewares';
import { useDispatch } from 'src/store';
import CustomerPdf from 'src/screens/customerPdf/CustomerPdf';

import Loadable from './Loadable';

const AuthContent = Loadable(
  lazy(() => import('src/screens/AuthContent/AuthContent')),
  () => {
    return <></>;
  },
);
const MainContent = Loadable(
  lazy(() => import('src/screens/MainContent/MainContent')),
  () => {
    return <></>;
  },
);

function PrivateRoute({ component, path }) {
  const isLoggedIn = useSelector((state: State) => state.user.isLoggedIn);
  // const isLoggedIn = true;
  const history = useHistory();
  const dispatch = useDispatch();
  fetcher().interceptors.response.use(
    function success(response) {
      return response;
    },
    function exception(error) {
      if (error && error.status === 401) {
        dispatch({ type: 'LOG_OUT', payload: { sessionExpired: true } });
      }
    },
  );

  const ele = isLoggedIn ? component : history.push('/auth');
  return <Route path={path} component={ele} />;
}

function Router(): JSX.Element {
  return (
    <MainRouter>
      <Switch>
        <Route path="/auth" component={AuthContent} />
        <Route path="/login/:authToken" component={AuthContent} />
        <Route path="/contracts/:token" component={CustomerPdf} />
        <PrivateRoute path="/main/:id" component={MainContent} />
        <Route path="*" component={AuthContent}></Route>
      </Switch>
    </MainRouter>
  );
}

export default Router;
