import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { once, set } from 'lodash/fp';

type URLS =
  | 'http://localhost:8000/api/v1'
  | 'https://app-staging.tagbiz.io/api/v1'
  | 'https://server.tagbiz.io/api/v1'
  | 'http://or.local:8000/api/v1'
  | 'http://172.20.10.4:8000/api/v1';

function setBaseUrl(baseUrl: URLS) {
  $fetcher.defaults.baseURL = baseUrl;
}
export function getBaseUrl(): string {
  return $fetcher.defaults.baseURL;
}
export const setNetwork = once(setBaseUrl);

const $fetcher = axios.create({
  baseURL: undefined,
  responseType: 'json',
  withCredentials: true,
});

$fetcher.interceptors.request.use(
  function success(request) {
    console.info('request', request);
    console.log('response', request);

    return request;
  },
  function exception(error) {
    console.info('request', error.toJSON());
    console.log('response', error.toJSON());
  },
);

$fetcher.interceptors.response.use(
  function success(response) {
    console.log('response', response);
    //console.log('response', response);
    return response;
  },
  function exception(error) {
    console.info('response', error.toJSON());
    console.log('response', error.toJSON());
    if (!error.response) {
      const withResponse = set(
        'response',
        {
          status: 500,
        },
        error,
      );

      return Promise.reject(withResponse);
    }
    return Promise.reject(error.response);
  },
);
const $get = $fetcher.get;
const $post = $fetcher.post;
const $delete = $fetcher.delete;

$fetcher.delete = function del(url: string, config: AxiosRequestConfig = {}) {
  return new Promise((resolve) => {
    resolve($delete(url, { ...config }));
  });
};

$fetcher.get = function get(url: string, config: AxiosRequestConfig = {}) {
  return new Promise((resolve) => {
    resolve($get(url, { ...config }));
  });
};

$fetcher.post = function post(
  url: string,
  data?: any,
  config: AxiosRequestConfig = {},
) {
  return new Promise((resolve) => {
    resolve($post(url, data, { ...config }));
  });
};

export default function fetcher(): AxiosInstance {
  return $fetcher;
}
