import { flow, set } from 'lodash/fp';
import AT from 'src/constants/action-types';
import { CustomerItem } from './../@types/shared.d';

export interface UserReducerState {
  firstName: string;
  lastName: string;
  email: string;
  businessId: string;
  profilePicture: string;
  events: any;
  contracts: number;
  missions: any;
  customersList: CustomerItem[];
  isLoggedIn: boolean;
}
const initialState: UserReducerState = {
  firstName: '',
  lastName: '',
  email: '',
  businessId: '',
  profilePicture: '',
  events: {},
  contracts: 0,
  missions: {},
  customersList: [],
  isLoggedIn: false,
};
export type LoginType = 'GM' | 'FB' | 'UP';

export type Actions =
  | { type: 'SET_USER'; payload: AT['SET_USER'] }
  | { type: 'SET_REMEMBER_ME'; payload: AT['SET_REMEMBER_ME'] }
  | {
      type: 'GET_CUSTOMERS_LIST_SUCCESS';
      payload?: AT['GET_CUSTOMERS_LIST_SUCCESS'];
    }
  | {
      type: 'GET_CUSTOMERS_LIST_FAILED';
      payload?: AT['GET_CUSTOMERS_LIST_FAILED'];
    }
  | {
      type: 'LOG_OUT';
      payload?: AT['LOG_OUT'];
    }
  | {
      type: 'LOG_OUT_SUCCESS';
      payload?: AT['LOG_OUT_SUCCESS'];
    }
  | {
      type: 'LOG_OUT_FAILED';
      payload?: AT['LOG_OUT_FAILED'];
    };
/**
 * User Reducer function
 */
export default function reducer(
  state: UserReducerState = localStorage.getItem('uiState')
    ? JSON.parse(localStorage.getItem('uiState'))
    : initialState,
  action: Actions,
): UserReducerState {
  const { type, payload } = action;
  switch (type) {
    case 'SET_USER': {
      const user = payload as AT[typeof type];
      return flow([
        set('firstName', user.firstName),
        set('lastName', user.lastName),
        set('email', user.email),
        set('contracts', user.contracts),
        set('profilePicture', user.profilePicture),
        set('businessId', user.businessId),
        set('events', user.events),
        set('missions', user.missions),
        set('isLoggedIn', true),
      ])(state);
    }
    case 'SET_REMEMBER_ME': {
      const { isRemember, email } = payload as AT[typeof type];
      if (isRemember && email) {
        localStorage.setItem('rememberMe', email);
      } else {
        localStorage.removeItem('rememberMe');
      }
      return state;
    }
    case 'GET_CUSTOMERS_LIST_SUCCESS': {
      const customerList = payload as AT[typeof type];
      return set('customersList', customerList, state);
    }
    case 'LOG_OUT_SUCCESS': {
      return set('isLoggedIn', false, state);
    }

    default:
      break;
  }
  return state;
}
