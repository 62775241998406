import { produce } from 'immer';
import { isUndefined } from 'lodash';
import { flow, set } from 'lodash/fp';
import { Event, FileItem, TaskItem } from 'src/@types/common';
import { getCustomerEventNormalized } from 'src/api/Logic/DataLogic';
import AT from 'src/constants/action-types';
import { SCREENS } from 'src/constants/strings';
import { ThemeColor } from 'src/theme/theme';

export interface UiReducerState {
  authContentType: AuthContentTypeEnum;
  mainContentType: MainContentTypeEnum;
  topSnackBar: {
    display: 'none' | 'flex';
    color: ThemeColor;
    title: string;
  };
  customerDataUi: CustomerDataUiType;
  statusOpenList: boolean;
  filterByList: boolean;
  uploadFileProgressTime: number;
}
export enum AuthContentTypeEnum {
  SPLASH,
  LOGIN,
  SIGN_UP,
  FORGOT_PASSWORD,
  EMAIL_CONFIRMATION,
  MAIN,
  RESET_PASSWORD,
}
export enum MainContentTypeEnum {
  CONTROL_PANEL = 'controlPanel',
  MY_CUSTOMERS = 'myCustomers',
  MY_LEADS = 'myLeads',
  MY_EVENTS = 'myEvents',
  MY_CALENDAR = 'myCalendar',
  MY_TASKS = 'myTasks',
  SETTINGS = 'settings',
  CONTACT_US = 'contactUs',
  ADD_CUSTOMER = 'addCustomer',
  CONTRACT_PREVIEW = 'contractPreview',
}

const initialState: UiReducerState = {
  authContentType: AuthContentTypeEnum.LOGIN,
  mainContentType: MainContentTypeEnum.MY_CUSTOMERS,
  topSnackBar: { display: 'none', color: 'transparent', title: '' },
  customerDataUi: {
    main: {
      firstName: '',
      lastName: '',
      personalId: '',
      otherPersonalId: '',
      email: '',
      phoneNumber: '',
      address: '',
      additionalPhone: '',
      source: '',
      comment: '',
      id: null,
      businessId: null,
      status: SCREENS.STATUS_CUSTOMER_NEW,
    },
    events: [],
    files: [],
    tasks: [],
  },
  statusOpenList: false,
  filterByList: false,
  uploadFileProgressTime: 0,
};
type CustomerMainType = {
  firstName: string;
  lastName: string;
  personalId: string;
  otherPersonalId: string;
  email: string;
  phoneNumber: string;
  address: string;
  additionalPhone: string;
  source: string;
  comment: string;
  id: number | null;
  businessId: number | null;
  status: string;
};
export type CustomerDataUiType = {
  files: FileItem[];
  tasks: TaskItem[];
  events: Event[];
  main: CustomerMainType;
};
export type Actions =
  | {
      type: 'SHOW_TOP_SNACK_BAR';
      payload: AT['SHOW_TOP_SNACK_BAR'];
    }
  | {
      type: 'SET_AUTH_CONTENT_TYPE';
      payload: AT['SET_AUTH_CONTENT_TYPE'];
    }
  | {
      type: 'SET_MAIN_CONTENT_TYPE';
      payload: AT['SET_MAIN_CONTENT_TYPE'];
    }
  | {
      type: 'SET_STATUS_OPEN_LIST';
      payload: AT['SET_STATUS_OPEN_LIST'];
    }
  | {
      type: 'SET_CUSTOMER_DATA_UI';
      payload?: AT['SET_CUSTOMER_DATA_UI'];
    }
  | {
      type: 'CHANGE_CUSTOMER_DATA_UI_PARAMS';
      payload?: AT['CHANGE_CUSTOMER_DATA_UI_PARAMS'];
    }
  | {
      type: 'SET_CUSTOMER_TASKS';
      payload?: AT['SET_CUSTOMER_TASKS'];
    }
  | {
      type: 'UPDATE_CUSTOMER_FILES';
      payload: AT['UPDATE_CUSTOMER_FILES'];
    }
  | {
      type: 'UPDATE_EVENT_STATUS';
      payload: AT['UPDATE_EVENT_STATUS'];
    }
  | {
      type: 'SET_UPLOAD_FILE_PROGRESS_BAR';
      payload: AT['SET_UPLOAD_FILE_PROGRESS_BAR'];
    };

export default function reducer(
  state: UiReducerState = initialState,
  action: Actions,
): UiReducerState {
  const { type, payload } = action;

  switch (type) {
    case 'SET_MAIN_CONTENT_TYPE': {
      const mainContentType = payload as AT[typeof type];
      return set('mainContentType', mainContentType, state);
    }
    case 'SET_AUTH_CONTENT_TYPE': {
      const authContentType = payload as AT[typeof type];
      return set('authContentType', authContentType, state);
    }

    case 'SHOW_TOP_SNACK_BAR': {
      const snackBar = payload as AT[typeof type];
      return flow([
        set('topSnackBar.display', snackBar.display),
        set('topSnackBar.color', snackBar.color),
        set('topSnackBar.title', snackBar.title),
      ])(state);
    }
    case 'SET_STATUS_OPEN_LIST': {
      const status = payload as AT[typeof type];
      return set('statusOpenList', status, state);
    }
    case 'CHANGE_CUSTOMER_DATA_UI_PARAMS': {
      const { key, value } = payload as AT[typeof type];
      return set(`draft.customerDataUi.events.${key}`, value, state);
    }

    case 'SET_CUSTOMER_DATA_UI': {
      if (!isUndefined(payload)) {
        const { files, main, events, tasks } = payload as AT[typeof type];
        const id = payload.id || main.id || -1;
        return produce(state, (draft) => {
          (draft.customerDataUi.main = main),
            (draft.customerDataUi.main.id = id),
            (draft.customerDataUi.events = getCustomerEventNormalized(events)),
            (draft.customerDataUi.files = files),
            (draft.customerDataUi.tasks = tasks);
        });
      }
      return produce(state, (draft) => {
        draft.customerDataUi = initialState.customerDataUi;
      });
    }
    case 'SET_CUSTOMER_TASKS': {
      const tasks = payload as AT[typeof type];
      return produce(state, (draft) => {
        draft.customerDataUi.tasks = tasks;
      });
    }
    case 'UPDATE_CUSTOMER_FILES': {
      const file = payload as AT[typeof type];
      return produce(state, (draft) => {
        const updateFileList = draft.customerDataUi.files.filter(
          ($file) => $file.id !== file.id,
        );
        if (updateFileList.length === draft.customerDataUi.files.length) {
          draft.customerDataUi.files.push(file);
        } else {
          draft.customerDataUi.files = updateFileList;
        }
      });
    }
    case 'SET_UPLOAD_FILE_PROGRESS_BAR': {
      const time = payload as AT[typeof type];
      return produce(state, (draft) => {
        draft.uploadFileProgressTime = time;
      });
    }

    default:
      break;
  }

  return state;
}
