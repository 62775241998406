import { GridSize } from '@material-ui/core';
import { isEqual, isUndefined } from 'lodash/fp';
import {
  CalendarEvent,
  Event,
  EventStatusType,
  Field,
  PermanentField,
  Service,
  TableField,
} from 'src/@types/common';
import { ContractSettings } from 'src/@types/shared';
import { InputType } from 'src/components/Input/Input';
import { BusinessType } from 'src/screens/MainContent/UserSettings/BusinessTab/BusinessTabNew';

type IResponse = any;
export const LINE_SPACE = 21;
export const LETTER_SPACE = 20;

export type CustomerDetails = {
  name: string;
  additionalName: string;
  phoneNumber: string;
  additionalPhone: string;
  address: string;
  personalId: string;
  otherPersonalId: string;
};

export type CustomerDetailsFormatterType = {
  [key: string]: {
    label: string;
    type: string;
    mandatory: boolean;
  };
};

export const getSize = (str: string): string[] => {
  const ans = str.split('_');
  return ans;
};
export const getCustomerEventNormalized = (events: any) => {
  const updateEvents = events.map((event: { [x: string]: number }) => {
    if (isUndefined(event['uniqueNum'])) {
      event['uniqueNum'] = event['_id'] + Date.now();
    }
    return event;
  });
  return updateEvents;
};

export const getPdfPagesWithLocationFormatted = (
  data: IResponse,
): { base64: string; size: string; page: string }[] => {
  const { contract } = data;

  return contract;
};
export const getPdfPagesFormatted = (data: IResponse): { base64: string }[] => {
  const { contract } = data;

  return contract;
};

export const getCustomerPdfPagesFormatted = (
  data: IResponse,
): {
  contract: { base64: string }[];
  details: CustomerDetails;
} => {
  return data;
};

export const customerDetailsFormatter: CustomerDetailsFormatterType = {
  name: { label: 'שם', type: 'text', mandatory: true },
  additionalName: { label: 'שם נוסף', type: 'text', mandatory: false },
  phoneNumber: { label: 'טלפון', type: 'number', mandatory: true },
  additionalPhone: { label: 'טלפון נוסף', type: 'number', mandatory: false },
  address: { label: 'כתובת', type: 'text', mandatory: false },
  personalId: { label: 'תעודת זהות', type: 'number', mandatory: true },
  otherPersonalId: {
    label: 'תעודת זהות נוספת',
    type: 'number',
    mandatory: false,
  },
};
export const getEventsFormatted = (data: IResponse): CalendarEvent[] => {
  const events: CalendarEvent[] = [];
  // const xs = [
  //   ...data,
  //   {
  //     category: 'holiday',
  //     start: {
  //       date: '2024-09-13',
  //     },
  //     hebrew: 'תשעה באב',
  //     startTime: 1726185600,
  //   },
  //   {
  //     category: 'holiday',
  //     start: {
  //       date: '2024-09-13',
  //     },
  //     hebrew: 'תשעה באב',
  //     startTime: 1726185600,
  //   },
  // ];
  data.map((item) => {
    if (item['category'] === 'holiday') {
      events.push({
        ...item,
        type: 'HolidayEvent',
        startTime: item.startTime,
      });
      return;
    }
    if (item['eventName'] === undefined) {
      events.push({ ...item, type: 'GoogleEvent' });
      return;
    }

    const payment = item['מחיר החבילה'] || item['סכום לתשלום'];
    const advancePayment = item['סכום המקדמה'] || item['מקדמה ששולמה'];
    const eventItem: Event = {};
    eventItem.eventName = item['eventName'];
    eventItem.eventDate = item['תאריך האירוע'];
    eventItem.eventStart = item['משעה'];
    eventItem.eventEnd = item['עד שעה'];
    eventItem.eventLocation = item['מיקום האירוע'];
    eventItem.eventNumber = item['phoneNumber'];
    eventItem.eventEmail = item['email'];
    eventItem.eventOwner = `${item['firstName']} ${
      item['lastName'] ? ' ו' + item['lastName'] : ''
    }`;
    eventItem.eventId = item['_id'];
    eventItem.eventCustomerId = item['customerId'];
    eventItem.eventStatus = item['eventStatus'];
    eventItem.eventCreatedDate = item['eventCreatedDate'];
    eventItem.eventPrice = {
      payment: payment.value || '0',
      advancePayment: advancePayment.value || '0',
    };
    events.push({ ...eventItem, type: 'TagbizEvent' });
  });
  return events;
};
export const convertServiceToEvent = (service: Service): Event => {
  const { serviceName, serviceStatus, fieldsItems } = service;
  const item = { eventName: serviceName, eventStatus: serviceStatus };
  fieldsItems.map((field) => {
    item[field.title] = {
      value: field.value || field.defaultValue,
      position: `${field.num}_${field.size}`,
      type: field.type,
      array: field.array,
      mandatory: field.mandatory,
      color: field.color,
      //defaultValue: field.defaultValue,
    };
  });
  return item;
};
export const getBusinessServicesFormatted = (data: IResponse): Service[] => {
  const services: Service[] = (data ?? []).map((serviceItem) => {
    const fieldsItems = [];
    let serviceName = '';
    let serviceStatus = '';
    let isDefault = false;
    for (const [key, value] of Object.entries(serviceItem)) {
      if (isEqual(key, 'serviceName')) {
        serviceName = value as string;
      } else if (isEqual(key, 'isDefault')) {
        isDefault = value as boolean;
      } else if (isEqual(key, 'serviceStatus')) {
        serviceStatus = value as string;
      } else {
        const fieldsValues = value as {
          position: string;
          type: InputType;
          value: string;
          array: string[];
          fieldValue: string;
          mandatory: boolean;
          color: string;
          defaultValue: string;
        };
        const field: Field = {
          title: key,
          size: getSize(fieldsValues.position)[1] as GridSize,
          type: fieldsValues.type,
          array: fieldsValues.array,
          value: fieldsValues.fieldValue,
          num: getSize(fieldsValues.position)[0],
          mandatory: fieldsValues.mandatory,
          color: fieldsValues.color,
          defaultValue: fieldsValues.defaultValue,
        };
        fieldsItems.push(field);
      }
    }
    return { fieldsItems, serviceName, serviceStatus };
  });

  return services;
};

export const getContractFieldsFormatted = (
  data: IResponse,
): {
  general: PermanentField[];
  events: PermanentField[];
  mail: PermanentField[];
  tableFields: TableField[];
} => {
  const { events, general, mail } = data;
  const generalFields = general.map((field): PermanentField => {
    return {
      title: field.title,
      isChecked: field.isChecked,
      editorHtml: field.html.replace('\\', ''),
      businessId: field.businessId,
      id: field.id,
    };
  });
  const eventsFields = events.map((field): PermanentField => {
    return {
      title: field.title,
      isChecked: true,
      editorHtml: field.html.replace('\\', ''),
      businessId: field.businessId,
      id: field.id,
    };
  });
  const mailFields = mail.map((field): PermanentField => {
    return {
      title: field.title,
      isChecked: true,
      editorHtml: field.html.replace('\\', ''),
      businessId: field.businessId,
      id: field.id,
    };
  });
  const tableFields = events.map((event) => {
    return parseEventTable(event.contractTableField);
  });

  return {
    general: generalFields,
    events: eventsFields,
    mail: mailFields,
    tableFields: tableFields,
  };
};

export const getCustomerContractFieldsFormatted = (
  data: IResponse,
): {
  general: PermanentField[];
  events: PermanentField[];
  mail: PermanentField[];
  tableFields: TableField[];
} => {
  const { events, general, mail } = data;
  const generalFields = general.map((field): PermanentField => {
    return {
      title: field.title,
      isChecked: field.isChecked,
      editorHtml: field.html.replace('\\', ''),
      businessId: field.businessId,
      id: field.id,
    };
  });
  const eventsFields = events.map((field): PermanentField => {
    return {
      title: field.title,
      isChecked: true,
      editorHtml: field.html.replace('\\', ''),
      businessId: field.businessId,
      id: field.id,
    };
  });
  const mailFields = mail.map((field): PermanentField => {
    return {
      title: field.title,
      isChecked: true,
      editorHtml: field.html.replace('\\', ''),
      businessId: field.businessId,
      id: field.id,
    };
  });
  const tableFields = events.map((event) => {
    return parseEventTable(event.customerContractTableField);
  });

  return {
    general: generalFields,
    events: eventsFields,
    mail: mailFields,
    tableFields: tableFields,
  };
};

export const sendContractFieldsToServer = (
  general: PermanentField[],
  events: PermanentField[],
  tableFields: TableField[],
): ContractFormattedData => {
  const generalFieldsFormated = general.map((field) => {
    return {
      title: field.title,
      html: field.editorHtml,
      height: field.height,
      isChecked: field.isChecked,
    };
  });
  const eventsFieldsFormated = events.map((field, index) => {
    return {
      title: field.title,
      html: field.editorHtml,
      isChecked: field.isChecked,
      height: field.height,
      contractTableField: stringifyEventTable(tableFields[index]),
    };
  });
  return { general: generalFieldsFormated, events: eventsFieldsFormated };
};

export const getBusinessDetailsFormatted = (data: IResponse): BusinessType => {
  return {
    businessName: data.name,
    businessPhoneNumber: data.phone,
    businessNumber: data.number,
    businessAddress: data.address,
    businessEmail: data.email,
    businessBank: data.bank,
    businessAccount: data.account,
    businessBranch: data.branch,
    businessSignature: data.signature64
      ? `data:img/png;base64,${data.signature64}`
      : null,
    businessLogo: data.logo64 ? `data:img/png;base64,${data.logo64}` : null,
    businessOwnerName: data.companyOwner,
    businessWebsite: data.companySite,
    businessLeadsKeys: data.businessLeadsKeys,
  };
};

export const getContractSettingFormatted = (
  data: IResponse,
): ContractSettings => {
  const { contract } = data;
  return contract;
};

const parseEventTable = (table: any): TableField => {
  return {
    cols: JSON.parse(table.cols),
    id: table.id,
    serviceCols: JSON.parse(table.serviceCols),
    title: table.title,
  };
};
export const stringifyEventTable = (table: TableField): any => {
  return {
    cols: JSON.stringify(table.cols),
    id: table.id,
    serviceCols: JSON.stringify(table.serviceCols),
    title: table.title,
  };
};
export interface FormattedField {
  title: string;
  html: string;
  height: number;
  isChecked: boolean;
  contractTableField?: string; // Optional field
}
export interface ContractFormattedData {
  general: FormattedField[];
  events: FormattedField[];
}

export type DashboardData = {
  tasks: { done: number; unDone: number };
  contracts: { signed: number; pending: number };
  incomeBoard: { month: number; income: number }[];
  approvedServices: number;
  notInterested: number;
  newCustomer: number;
  close: number;
  admin: boolean;
};

export const sendUpdatedFieldsToServer = ({
  generalFields,
  eventsFields,
  mailFields,
  tableFields,
}: {
  generalFields: PermanentField[];
  eventsFields: PermanentField[];
  mailFields: PermanentField[];
  tableFields: TableField[];
}) => {
  const body = { general: [], events: [], mail: [] };
  generalFields.map((field, index) => {
    body.general.push({
      id: field.id,
      html: field.editorHtml,
      businessId: field.businessId,
      isChecked: field.isChecked,
      height: field.height,
      title: field.title,
    });
  });
  eventsFields.map((field, index) => {
    body.events.push({
      id: field.id,
      html: field.editorHtml,
      businessId: field.businessId,
      isChecked: field.isChecked,
      height: field.height,
      title: field.title,
      contractTableField: stringifyEventTable(tableFields[index]),
    });
  });
  mailFields.map((field, index) => {
    body.mail.push({
      id: field.id,
      html: field.editorHtml,
      businessId: field.businessId,
      isChecked: field.isChecked,
      title: field.title,
    });
  });
  return body;
};
export const sendUpdatedCustomerFieldsToServer = ({
  generalFields,
  eventsFields,
  mailFields,
  tableFields,
}: {
  generalFields: PermanentField[];
  eventsFields: PermanentField[];
  mailFields: PermanentField[];
  tableFields: TableField[];
}) => {
  const body = { general: [], events: [] };
  generalFields.map((field, index) => {
    body.general.push({
      id: field.id,
      html: field.editorHtml,
      businessId: field.businessId,
      isChecked: field.isChecked,
      height: field.height,
      title: field.title,
    });
  });
  eventsFields.map((field, index) => {
    body.events.push({
      id: field.id,
      html: field.editorHtml,
      businessId: field.businessId,
      isChecked: field.isChecked,
      height: field.height,
      title: field.title,
      customerContractTableField: stringifyEventTable(tableFields[index]),
    });
  });

  return body;
};

export const getStatusesFormatted = (data: any): EventStatusType[] => {
  return data;
};
