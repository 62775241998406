import styled from 'styled-components';
import { space, color, layout, typography } from 'styled-system';
import { TextProps } from './Text';

const Title = styled.h1.attrs((props: TextProps) => ({
  style: props.style,
}))<TextProps>`
  font-size: 25px;
  font-weight: bold;
  text-align: right;
  color: ${'blackText'};
  ${space} ${color} ${layout}
    ${typography};
`;

export default Title;
