import { NotificationType } from './AddNotification';
import fetcher from 'src/api/RequestHandler';
import { ROUTES } from 'src/api/Routes';

export type CreateNotificationResponse = {
  message: string;
  notificationId: number;
};

export function createNotification(
  values: NotificationType,
): Promise<CreateNotificationResponse> {
  const {
    mailMessage,
    mailTitle,
    notificationDesc,
    notificationName,
    whatsappMessage,
  } = values;
  const body = {
    mailMessage,
    mailTitle,
    notificationDesc,
    notificationName,
    whatsappMessage,
  };
  const dataPromise = fetcher()
    .post(ROUTES.POST.CREATE_NOTIFICATION, body)
    .then(({ data }) => {
      return data;
    })
    .catch(() => {
      return {};
    });

  return dataPromise;
}

export type NotificationFile = {
  file: string;
  id: number;
  fileName: string;
};
export function uploadNotificationFile({
  files,
  notificationId,
}: {
  files: File[];
  notificationId: string;
}): Promise<NotificationFile> {
  const formData = new FormData();
  files.map((file) => {
    formData.append(file.name, file);
  });
  const promise = fetcher()
    .post(ROUTES.POST.UPLOAD_FILE_TO_NOTIFICATION({ notificationId }), formData)
    .then(({ data }) => {
      return data;
    })
    .catch(() => {
      return {};
    });
  return promise;
}
export function fetchNotifications(): Promise<NotificationType[]> {
  const promise = fetcher()
    .get(ROUTES.GET.GET_NOTIFICATIONS)
    .then(({ data }) => {
      return data;
    })
    .catch(() => {
      return [];
    });

  return promise;
}

export function updateNotification(
  values: NotificationType,
): Promise<CreateNotificationResponse> {
  const {
    mailMessage,
    mailTitle,
    notificationDesc,
    notificationName,
    whatsappMessage,
    id,
  } = values;
  const body = {
    mailMessage,
    mailTitle,
    notificationDesc,
    notificationName,
    whatsappMessage,
    id,
  };
  const dataPromise = fetcher()
    .post(ROUTES.POST.UPDATE_NOTIFICATION({ notificationId: id }), body)
    .then(({ data }) => {
      return data;
    })
    .catch(() => {
      return {};
    });

  return dataPromise;
}

export function deleteNotification(id: number): Promise<void> {
  const promise = fetcher()
    .delete(ROUTES.DELETE.DELETE_NOTIFICATION({ notificationId: id }))
    .then(({ data }) => {
      return data;
    })
    .catch(() => {
      return [];
    });

  return promise;
}
export function deleteNotificationFile({
  notificationId,
  fileId,
}: {
  fileId: number;
  notificationId: number;
}): Promise<void> {
  const promise = fetcher()
    .delete(
      ROUTES.DELETE.DELETE_NOTIFICATION_FILE({
        notificationId: notificationId,
        fileId: fileId,
      }),
    )
    .then(({ data }) => {
      return data;
    })
    .catch(() => {
      return [];
    });

  return promise;
}
