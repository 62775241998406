import Modal from 'react-modal'; // It can be any modal
import { Box } from 'src/theme';
import { MODALS, MODAL_TYPES } from 'src/@types/navigation';
import template2 from '../images/contract/templates/template1.svg';
import template1 from '../images/contract/templates/template2.svg';
import Pattern from '../components/Pattern/Pattern';

export default function PatternsModal({
  isOpen,
  onClose,
  saveCallBack,
  contentStyle,
}: MODALS[MODAL_TYPES.PATTERNS]): JSX.Element {
  function onSave(key: number) {
    saveCallBack(key);
    onClose();
  }

  type ContactTemplate = {
    pic: string;
    template_key: number;
  };
  function renderTemplates() {
    const templatesArray: ContactTemplate[] = [
      { pic: template1, template_key: 1 },
      { pic: template2, template_key: 2 },
    ];
    return (
      <Box display="flex">
        {templatesArray.map((template) => {
          return (
            <Pattern
              src={template.pic}
              onClick={() => {
                onSave(template.template_key);
              }}
            />
          );
        })}
      </Box>
    );
  }

  return (
    <Modal
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: '#373737BF',
        },
        content: contentStyle,
      }}
      isOpen={isOpen}
      onRequestClose={onClose}>
      <Box textAlign={'center'} px={40} py={10}>
        {renderTemplates()}
      </Box>
    </Modal>
  );
}
