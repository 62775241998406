import { Box, Text } from 'src/theme';
import { ThemeColor } from 'src/theme/theme';

type Props = {
  title: string;
  onClick?: () => void;
  color?: ThemeColor;
  size?: number;
  icon?: string;
  style?: any;
  cursor?: string;
  fontColor?: ThemeColor;
  borderColor?: ThemeColor;
  visibility?: boolean;
};

const CustomButton = (props: Props): JSX.Element => {
  const {
    style,
    title,
    onClick,
    color,
    size,
    icon,
    cursor = 'pointer',
    fontColor,
    borderColor,
  }: Props = props;
  return (
    <Box
      display={'flex'}
      justifyContent="center"
      borderRadius={7}
      borderColor={borderColor ? borderColor : 'white'}
      alignItems="center"
      backgroundColor={color}
      style={{ ...style, cursor: cursor }}
      onClick={onClick}>
      {icon ? (
        <img
          style={{ height: 19, marginLeft: 10 }}
          alt="customBtnIcon"
          src={icon}
        />
      ) : null}
      <Text color={fontColor ? fontColor : 'white'}> {title}</Text>
    </Box>
  );
};

export default CustomButton;
