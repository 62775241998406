import { useState } from 'react';
import { Box, Text } from 'src/theme';
import Button from 'src/theme/Button';
import Title from 'src/theme/Title';

type PatternProps = {
  src: string;
  onClick: () => void;
};

const height = 200;
const width = 140;

const Pattern = ({ src, onClick }: PatternProps): JSX.Element => {
  const [focus, setFocus] = useState(false);

  return (
    <Box
      onClick={onClick}
      mx={4}
      onMouseEnter={() => {
        setFocus(true);
      }}
      onMouseLeave={() => {
        setFocus(false);
      }}>
      {!focus && (
        <img style={{ width: width, height: height }} src={src} alt="pattern" />
      )}
      {focus && (
        <Box>
          <Box
            zIndex={1000}
            width={width}
            height={height}
            backgroundColor="purple6EA2FFD9"
            style={{ position: 'absolute' }}>
            <Title pt={60} textAlign="center" fontSize={14} color={'white'}>
              {'הצעת מחיר'}
            </Title>
            <Box mt={4}>
              <Button
                color={'white'}
                borderColor={'purple6EA2FFD9'}
                height="28px"
                width="95px"
                radius="3px"
                onClick={() => {}}>
                <Text color={'purple6EA2FFD9'}>{'בחר תבנית'}</Text>
              </Button>
            </Box>
          </Box>
          <img
            style={{ width: width, height: height }}
            src={src}
            alt="pattern"
          />
        </Box>
      )}
    </Box>
  );
};

export default Pattern;
