import Modal from 'react-modal'; // It can be any modal
import { SCREENS } from 'src/constants/strings';
import { Box, Text } from 'src/theme';
import { MODALS, MODAL_TYPES } from 'src/@types/navigation';
import ModalButton from './ModalButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Radio, RadioGroup, ThemeProvider, createTheme } from '@mui/material';
import { isEmpty } from 'lodash/fp';
import { mobileMaxWidth } from 'src/utils/responsiveSize';
import { useMediaQuery } from 'react-responsive';
import { MutableRefObject, forwardRef, useRef } from 'react';

const Options = forwardRef(
  (
    { options }: { options: { id: number; label: string }[] },
    ref: MutableRefObject<number>,
  ): JSX.Element | null => {
    const isMobile = useMediaQuery({ query: mobileMaxWidth });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedId = parseInt(event.target.value, 10);
      if (!isNaN(selectedId)) {
        ref.current = selectedId;
      }
    };
    const muiTheme = createTheme({
      components: {
        MuiFormControlLabel: {
          styleOverrides: {
            label: { fontSize: isMobile ? 12 : 16 },
            root: { margin: 0 },
          },
        },
      },
    });

    if (isEmpty(options)) {
      return null;
    }

    return (
      <ThemeProvider theme={muiTheme}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={0}
          name="radio-buttons-group">
          {options.map((item, index) => (
            <FormControlLabel
              key={item.id}
              value={item.id}
              control={<Radio />}
              label={item.label}
              onChange={handleChange}
            />
          ))}
        </RadioGroup>
      </ThemeProvider>
    );
  },
);
export default function OptionsModal({
  isOpen,
  onClose,
  title = SCREENS.SEND_CONTRACT_MODAL_TITLE,
  options,
  rightBtnText = SCREENS.SEND_CONTRACT_MODAL_SEND,
  leftBtnText = SCREENS.SEND_CONTRACT_MODAL_CLOSE,
  rightBtn,
  leftBtn,
  contentStyle,
}: MODALS[MODAL_TYPES.OPTIONS]): JSX.Element {
  const isMobile = useMediaQuery({ query: mobileMaxWidth });
  const ref = useRef<number>(0);

  return (
    <Modal
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: '#3737372E',
        },
        content: contentStyle,
      }}
      isOpen={isOpen}
      onRequestClose={onClose}>
      <Box px={1}>
        <Box display={'flex'} textAlign={'center'}>
          <Text fontWeight={400} fontSize={isMobile ? 16 : 18}>
            {title}
          </Text>
        </Box>
        <Options options={options} ref={ref} />
        <Box display="flex" pt={3}>
          <Box>
            <ModalButton
              onClick={
                rightBtn
                  ? () => {
                      rightBtn(ref.current);
                    }
                  : onClose
              }
              text={rightBtnText}
              type="blue"
            />
          </Box>
          <Box px={1}>
            <ModalButton
              onClick={
                leftBtn
                  ? () => {
                      leftBtn(ref.current);
                    }
                  : onClose
              }
              text={leftBtnText}
              type="transparent"
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
