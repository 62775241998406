import { Input, ThemeProvider, createTheme } from '@mui/material';
import { useRef, useState } from 'react';
import Modal from 'react-modal'; // It can be any modal
import { useMediaQuery } from 'react-responsive';
import SignatureCanvas from 'react-signature-canvas';

import { MODALS, MODAL_TYPES } from 'src/@types/navigation';
import closeIcon from 'src/images/close_icons/close_blue.svg';
import { Box, Text } from 'src/theme';
import { mobileMaxWidth } from 'src/utils/responsiveSize';

import { isEmpty } from 'lodash/fp';
import {
  CustomerDetails,
  customerDetailsFormatter,
} from 'src/api/Logic/DataLogic';
import theme from 'src/theme/theme';
import ModalButton from './ModalButton';

export default function SignatureModalWithId({
  isOpen,
  onClose,
  title = 'פרטי הלקוח',
  subTitle = 'חתימת הלקוח',
  saveCallBack,
  contentStyle,
  details,
}: MODALS[MODAL_TYPES.SIGNATURE]): JSX.Element {
  const canvasRef = useRef(null);
  const [values, setValues] = useState<CustomerDetails>(details);
  const [isErrorMode, setErrorMode] = useState(false);
  const isMobile = useMediaQuery({ query: mobileMaxWidth });

  function checkValidation() {
    let ans = true;

    Object.keys(customerDetailsFormatter).map((key) => {
      if (customerDetailsFormatter[key].mandatory && isEmpty(values[key])) {
        setErrorMode(true);
        ans = false;
      }
    });
    return ans;
  }

  function onSave() {
    if (!isErrorMode && checkValidation()) {
      saveCallBack({
        img: canvasRef.current.toDataURL(),
        details: values,
      });
      onClose();
    }
  }

  const handleChange = (key: string, value: string) => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };
  const muiTheme = createTheme({
    components: {
      MuiInput: {
        styleOverrides: {
          root: {
            fontSize: isMobile ? 14 : 16,
            width: isMobile ? '100%' : '70%',
            backgroundColor: theme.colors.greyf9f9f9,
            borderColor: theme.colors.greyE3E3E3,
            borderStyle: 'solid',
            paddingInline: 10,
            borderWidth: 1,
            borderRadius: 10,
          },
        },
      },
    },
  });
  return (
    <Modal
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: '#3737372E',
        },
        content: isMobile
          ? {
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              backgroundColor: theme.colors.whiteWithTransperent,
            }
          : {
              ...contentStyle,
              backgroundColor: theme.colors.whiteWithTransperent,
            },
      }}
      isOpen={isOpen}
      onRequestClose={onClose}>
      <Box onClick={onClose} style={{ cursor: 'pointer' }}>
        <img style={{ width: 30, height: 30 }} src={closeIcon} />
      </Box>
      <Box py={10} textAlign={'center'} px={isMobile ? 10 : 20}>
        <Text fontWeight={500} fontSize={18}>
          {title}
        </Text>
        <Box py={10} m={'auto'} textAlign={'center'}>
          {Object.keys(values).map((key) => {
            return (
              <Box alignItems={'center'} py={'0.2em'}>
                <ThemeProvider theme={muiTheme}>
                  <Input
                    sx={{ paddingTop: 0.5 }}
                    disableUnderline
                    key={key}
                    placeholder={
                      customerDetailsFormatter[key].mandatory
                        ? `${customerDetailsFormatter[key].label}*`
                        : customerDetailsFormatter[key].label
                    }
                    value={values[key]}
                    type={customerDetailsFormatter[key].type}
                    onChange={(e) => {
                      handleChange(key, e.target.value);
                      setErrorMode(false);
                    }}
                  />
                </ThemeProvider>
              </Box>
            );
          })}
        </Box>
        <Text py={10} mt={10} fontWeight={500} fontSize={16}>
          {subTitle}
        </Text>
        <Box
          borderWidth={2}
          borderColor="greyD9D9D9"
          borderStyle={'solid'}
          borderRadius={20}>
          <SignatureCanvas
            ref={canvasRef}
            penColor="black"
            canvasProps={{
              width: isMobile ? 150 : 400,
              height: isMobile ? 150 : 200,
              className: 'sigCanvas',
            }}
          />
        </Box>
        {isErrorMode && (
          <Text color={'redError'} fontSize={isMobile ? 12 : 16}>
            *חתימה ומילוי השדות חובה
          </Text>
        )}
        <Box mt={10} justifyContent="center" display="flex">
          <Box px={1}>
            <ModalButton onClick={onSave} text={'שלח חוזה'} type="blue" />
          </Box>
          <Box px={1}>
            <ModalButton
              onClick={() => {
                if (canvasRef !== null) {
                  canvasRef.current.clear();
                }
              }}
              text={'אפס'}
              type="transparent"
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
