import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router';
import contract from 'src/images/contract.png';
import { Box, Text } from 'src/theme';
import { useModal } from 'use-modal-hook';

import fetcher from 'src/api/RequestHandler';
import { ROUTES } from 'src/api/Routes';
import CustomButton from 'src/components/CustomButton/CustomButton';
import { SCREENS } from 'src/constants/strings';
import Modal from 'src/modals/Modal';

import errorIcon from 'src/images/customer_pdf/error.svg';
import tagBizIcon from 'src/images/tagbiz_icons/tagbiz_icon_blue.svg';
import Button from 'src/theme/Button';
import { mobileMaxWidth } from 'src/utils/responsiveSize';

import { MODAL_TYPES } from 'src/@types/navigation.d';

import {
  CustomerDetails,
  getCustomerPdfPagesFormatted,
} from 'src/api/Logic/DataLogic';
import MyPdfView from '../MainContent/CustomerList/PdfViewer';
import ReactPlayer from 'react-player';
import jakePencil from 'src/images/videos/jake-pencil.mp4';

function fetchContractDetails(token: string): Promise<{
  contract?: { base64: string }[];
  details?: CustomerDetails;
}> {
  const promise = fetcher()
    .get(
      ROUTES.GET.GET_CUSTOMER_CONTRACT_DATA_BY_KEY({
        token,
      }),
    )
    .then((data) => {
      if (data.status !== 200) {
        Promise.reject();
      }
      return getCustomerPdfPagesFormatted(data.data);
    })
    .catch(() => {
      return Promise.reject();
    });
  return promise;
}

const CustomerPdf = (): JSX.Element => {
  const { token } = useParams<{ token: string }>();
  const [showModal, hideModal] = useModal(Modal);
  const [isSend, setSend] = useState(false);

  const isMobile = useMediaQuery({ query: mobileMaxWidth });

  function update({
    token,
    signature,
    details,
  }: {
    token: string;
    signature: string;
    details: CustomerDetails;
  }) {
    const promise = fetcher()
      .post(
        ROUTES.POST.SEND_SIGNED_CONTRACT_PDF({
          token,
        }),
        { signature: signature, final: true, details },

        {
          responseType: 'arraybuffer',
        },
      )
      .then(async (response) => {
        const pdfBlob = new Blob([response.data], {
          type: 'application/pdf',
        });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        return { url: pdfUrl };
      })

      .catch(() => {
        return { url: '' };
      });

    return promise;
  }

  const queryKey = [ROUTES.GET.GET_CUSTOMER_CONTRACT_DATA_BY_KEY({ token })];

  const mutation = useMutation(update, {
    onSuccess: () => {
      setSend(true);
      hideModal();
    },
    onError: () => {
      hideModal();
    },
  });

  const { isLoading, isError, data } = useQuery<{
    contract?: { base64: string }[];
    details?: CustomerDetails;
  }>(queryKey, () => fetchContractDetails(token), {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
  if (isSend) {
    return (
      <Box
        height={'100vh'}
        display={'flex'}
        px={isMobile ? 10 : 600}
        textAlign={'center'}
        alignItems={'center'}
        justifyContent={'center'}>
        <Box>
          <img style={{ marginLeft: 40 }} height={200} src={contract}></img>
          <Text
            pt={40}
            pb={40}
            fontWeight={600}
            alignSelf={'center'}
            fontSize={isMobile ? 20 : 30}>
            החוזה נחתם וישלח במייל
          </Text>
          <Text fontSize={isMobile ? 20 : 30}>
            שמחים שבחרתם בנו לקחת חלק באירוע שלכם. נמשיך לעדכן אתכם בכל מה
            שרלוונטי עבורכם ונתראה בקרוב!
          </Text>
        </Box>
      </Box>
    );
  }
  function loadingComponent() {
    return (
      <Box flex={1} mt={120}>
        <Box textAlign={'center'}>
          <ReactPlayer
            width={'100%'}
            stopOnUnmount={false}
            loop={true}
            playing={true}
            muted={true}
            url={jakePencil}
          />

          <Box flex={1} mt={-40}>
            <Text fontFamily={'serif'} color={'blue6ea2ff'} fontSize={26}>
              {SCREENS.CONTRACT_LOADING_TEXT_TITLE}
            </Text>
            <Text fontFamily={'serif'} color={'blue6ea2ff'} fontSize={18}>
              {SCREENS.CONTRACT_LOADING_TEXT_SUB_TITLE}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Box height={'100%'} flex={1} display={'flex'}>
        {loadingComponent()}
      </Box>
    );
  }
  if (isError) {
    return (
      <Box alignContent={'center'} height="100vh" alignItems={'center'}>
        <Box
          mt={'8%'}
          mx={'8%'}
          justifyContent={'space-between'}
          display={'flex'}>
          <Button
            color={'blue6ea2ff'}
            borderColor={'blue6ea2ff'}
            height="36px"
            width="132px"
            radius="7px"
            onClick={() => {
              window.open('https://tagbiz.io/contact-us/', '_blank');
            }}>
            <Text color={'white'}>{SCREENS.CUSTOMER_PDF_CONTACT}</Text>
          </Button>
          <img src={tagBizIcon} />
        </Box>
        <img
          style={{
            ...(isMobile && { height: 300 }),
            ...(isMobile && { width: 300 }),
            marginRight: 'auto',
            marginLeft: 'auto',
            display: 'block',
          }}
          src={errorIcon}
        />
        <Box>
          <Box>
            <Box>
              <Text fontWeight={'bold'} fontSize={25} textAlign={'center'}>
                {SCREENS.CUSTOMER_PDF_ERROR}
              </Text>
            </Box>
            <Box>
              <Text
                fontSize={18}
                mt={25}
                textAlign={'center'}
                color="blue0F70EB">
                {SCREENS.CUSTOMER_PDF_TRY_REFRESH}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  const onDigitalSignatureClick = (token: string) => {
    showModal({
      type: MODAL_TYPES.SIGNATURE,
      params: {
        details: data.details,
        isWithId: true,
        saveCallBack: (data: { img: string; details: CustomerDetails }) => {
          mutation.mutate({
            token,
            signature: data.img,
            details: data.details,
          });
        },
      },
    });
  };

  return (
    <Box
      height={isMobile ? '80vh' : '95vh'}
      overflow={'scroll'}
      flex={1}
      display={'flex'}>
      <MyPdfView pdfPages={data.contract} />
      {!isSend && (
        <Box
          width={'100%'}
          flex={1}
          position={'absolute'}
          justifyContent={'center'}
          bottom={1}
          py={10}
          display={'flex'}>
          <CustomButton
            onClick={() => onDigitalSignatureClick(token)}
            color={'blue6ea2ff'}
            fontColor={'blueD1E1FF'}
            title={SCREENS.SIGN_ON_CONTRACT}
            style={{
              marginHorizontal: 5,
              width: 150,
              height: 45,
              alignItems: 'center',
              textAlign: 'center',
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default CustomerPdf;
