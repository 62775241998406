import { useRef, useCallback } from 'react';
import Modal from 'react-modal'; // It can be any modal
import { useReactToPrint } from 'react-to-print';
import { Box } from 'src/theme';
import { MODALS, MODAL_TYPES } from 'src/@types/navigation';
import ModalButton from './ModalButton';

export default function ContractPreviewModal({
  isOpen,
  onClose,
  contract,
  contentStyle,
}: MODALS[MODAL_TYPES.CONTRACT_PREVIEW]): JSX.Element {
  const componentRef = useRef(null);

  const pageStyle = `
  @media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color;
      -adjust: exact;


    }
  }
  
  @media print {
    .page-break {
      display: block;
    }
  }
  
  @page {
    size: 130mm 210mm;
  }
`;
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'חוזה',
    pageStyle: pageStyle,
    removeAfterPrint: true,
  });

  function onDownload() {
    handlePrint();
  }
  function renderContract() {
    return (
      <Box ref={componentRef} overflowY={'scroll'}>
        {contract.map((page) => {
          return <Box my={3}>{page}</Box>;
        })}
      </Box>
    );
  }

  return (
    <Modal
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: '#373737BF',
        },
        content: contentStyle,
      }}
      isOpen={isOpen}
      onRequestClose={onClose}>
      <Box height={700} px={40} py={10}>
        {renderContract()}
        <Box pb={10} mt={10} justifyContent="center" display="flex">
          <Box px={1}>
            <ModalButton onClick={onClose} text={'סגור'} type="transparent" />
          </Box>
          {/* <Box px={1}>
            <ModalButton onClick={onDownload} text={'הורד'} type="blue" />
          </Box> */}
        </Box>
      </Box>
    </Modal>
  );
}
