import { EventStatusType } from 'src/@types/common';
import { getStatusesFormatted } from 'src/api/Logic/DataLogic';
import fetcher from 'src/api/RequestHandler';
import { ROUTES } from 'src/api/Routes';

type sendNotificationProps = {
  notificationId: number;
  notificationType: 'email' | 'whatsapp';
  customerId: number;
};

export function sendNotification(values: sendNotificationProps): Promise<void> {
  const { notificationId, notificationType, customerId } = values;
  const body = {
    notificationId,
    notificationType,
  };
  const dataPromise = fetcher()
    .post(ROUTES.POST.SEND_ALERT_TO_CUSTOMER({ customerId: customerId }), body)
    .then(({ data }) => {
      return data;
    })
    .catch(() => {
      return {};
    });

  return dataPromise;
}

export function fetchStatuses(): Promise<EventStatusType[]> {
  const promise = fetcher()
    .get(ROUTES.GET.GET_STATUSES)
    .then(({ data }) => {
      return getStatusesFormatted(data);
    })
    .catch(() => {
      return [];
    });

  return promise;
}
