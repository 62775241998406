import { ThunkDispatch } from 'redux-thunk';
import { Middleware } from 'redux';
import { ROUTES } from 'src/api/Routes';
import AT from 'src/constants/action-types';
import { Action } from 'src/reducers/index';
import fetcher from 'src/api/RequestHandler';
import { getBusinessServicesFormatted } from 'src/api/Logic/DataLogic';
import { State } from '.';
import { QueryClient } from 'react-query';
import { setUserAndPasswordInLocalStorage } from '../utils/storage';

export type FetchMiddlewareActions =
  | { type: 'EMAIL_CONFIRMATION'; payload: AT['EMAIL_CONFIRMATION'] }
  | {
      type: 'EMAIL_CONFIRMATION_SUCCESS';
      payload?: AT['EMAIL_CONFIRMATION_SUCCESS'];
    }
  | {
      type: 'EMAIL_CONFIRMATION_FAILED';
      payload?: AT['EMAIL_CONFIRMATION_FAILED'];
    }
  | {
      type: 'GET_BUSINESS_SERVICES';
      payload?: AT['GET_BUSINESS_SERVICES'];
    }
  | {
      type: 'GET_CUSTOMERS_LIST';
      payload?: AT['GET_CUSTOMERS_LIST'];
    };

const fetchMiddleware: Middleware<
  unknown,
  State,
  ThunkDispatch<State, unknown, Action>
> = (store) => (next) => (action: Action) => {
  next(action);

  const { dispatch, getState } = store;
  const { type, payload } = action;

  const errorHandler = (status: number) => {
    if (status === 401) {
      dispatch({ type: 'LOG_OUT', payload: { sessionExpired: true } });
    }
  };
  switch (type) {
    case 'EMAIL_CONFIRMATION': {
      dispatch(async function fetch() {
        try {
          const $payload = payload as AT[typeof type];
          const response = await fetcher().get(
            `${ROUTES.GET.CONFIRM}?${$payload}`,
          );
          if (response) {
            const { status } = response;
            if (status < 400) {
              dispatch({ type: 'EMAIL_CONFIRMATION_SUCCESS' });
            } else {
              errorHandler(status);
            }
          }
        } catch (err) {
          errorHandler(err.status);
          dispatch({ type: 'EMAIL_CONFIRMATION_FAILED' });
          return;
        }
      });
      break;
    }
    case 'EMAIL_CONFIRMATION_SUCCESS':
    case 'EMAIL_CONFIRMATION_FAILED': {
      break;
    }
    case 'GET_BUSINESS_SERVICES': {
      dispatch(async function fetch() {
        try {
          const response = await fetcher().get(
            ROUTES.GET.GET_BUSINESS_SERVICES,
          );
          if (response) {
            const { data, status } = response;
            if (status < 400) {
              const normalized = getBusinessServicesFormatted(data);
              dispatch({
                type: 'GET_BUSINESS_SERVICES_SUCCESS',
                payload: normalized,
              });
            } else {
              errorHandler(status);
            }
          }
        } catch (err) {
          errorHandler(err.status);
          dispatch({ type: 'GET_BUSINESS_SERVICES_FAILED' });
          return;
        }
      });
      break;
    }
    case 'GET_CUSTOMERS_LIST': {
      dispatch(async function fetch() {
        try {
          const response = await fetcher().get(ROUTES.GET.GET_COSTUMERS);
          if (response) {
            const { data, status } = response;
            if (status < 400) {
              dispatch({
                type: 'GET_CUSTOMERS_LIST_SUCCESS',
                payload: data,
              });
            } else {
              errorHandler(status);
            }
          }
        } catch (err) {
          errorHandler(err.status);
          dispatch({ type: 'GET_CUSTOMERS_LIST_FAILED' });
          return;
        }
      });

      break;
    }

    case 'LOG_OUT': {
      const queryClient = new QueryClient();
      dispatch(async function fetch() {
        const { sessionExpired } = payload as AT[typeof type];
        if (sessionExpired) {
          localStorage.removeItem('state');
          queryClient.clear();
          dispatch({ type: 'LOG_OUT_SUCCESS' });
        } else {
          try {
            const response = await fetcher().get(ROUTES.GET.LOGOUT);
            if (response) {
              const { status } = response;
              if (status < 400) {
                localStorage.removeItem('state');
                queryClient.clear();
                setUserAndPasswordInLocalStorage({ user: '', password: '' });
                dispatch({ type: 'LOG_OUT_SUCCESS' });
              } else {
                errorHandler(status);
              }
            }
          } catch (err) {
            errorHandler(401);
          }
        }
      });

      break;
    }
  }
};
export default fetchMiddleware;
