import { produce } from 'immer';
import AT from 'src/constants/action-types';
import { PermanentField, TableField } from 'src/@types/common';

export interface ContractReducerState {
  generalFields: PermanentField[];
  eventsFields: PermanentField[];
  mailFields: PermanentField[];
  tableFields: TableField[];
}
const initialState: ContractReducerState = {
  eventsFields: [],
  generalFields: [],
  mailFields: [],
  tableFields: [],
};

export type Actions =
  | {
      type: 'SET_GENERAL_FIELD';
      payload: AT['SET_GENERAL_FIELD'];
    }
  | {
      type: 'SET_EVENT_FIELD';
      payload: AT['SET_EVENT_FIELD'];
    }
  | {
      type: 'SET_MAIL_FIELD';
      payload: AT['SET_MAIL_FIELD'];
    }
  | {
      type: 'SET_PERMANENT_FIELDS';
      payload: AT['SET_PERMANENT_FIELDS'];
    }
  | {
      type: 'SET_TABLE_DATA';
      payload: AT['SET_TABLE_DATA'];
    };

export default function reducer(
  state: ContractReducerState = initialState,
  action: Actions,
): ContractReducerState {
  const { type } = action;
  switch (type) {
    case 'SET_GENERAL_FIELD': {
      const payload = action.payload as AT['SET_GENERAL_FIELD'];
      const updatedPermanentsFields = state.generalFields.map((field) => {
        if (field.id === payload.id) {
          return payload;
        }
        return field;
      });
      return produce(state, (draft) => {
        draft.generalFields = updatedPermanentsFields;
      });
    }
    case 'SET_EVENT_FIELD': {
      const payload = action.payload as AT['SET_EVENT_FIELD'];
      const updatedPermanentsFields = state.eventsFields.map((field) => {
        if (field.id === payload.id) {
          return payload;
        }
        return field;
      });
      return produce(state, (draft) => {
        draft.eventsFields = updatedPermanentsFields;
      });
    }
    case 'SET_MAIL_FIELD': {
      const payload = action.payload as AT['SET_MAIL_FIELD'];
      const updatedPermanentsFields = state.mailFields.map((field) => {
        if (field.id === payload.id) {
          return payload;
        }
        return field;
      });
      return produce(state, (draft) => {
        draft.mailFields = updatedPermanentsFields;
      });
    }
    case 'SET_PERMANENT_FIELDS': {
      const payload = action.payload as AT['SET_PERMANENT_FIELDS'];

      return produce(state, (draft) => {
        const { general, events, mail, tableFields } = payload;
        draft.generalFields = general;
        draft.eventsFields = events;
        draft.mailFields = mail;
        draft.tableFields = tableFields;
      });
    }
    case 'SET_TABLE_DATA': {
      const payload = action.payload as AT['SET_TABLE_DATA'];
      return produce(state, (draft) => {
        draft.tableFields = payload;
      });
    }

    default:
      break;
  }

  return state;
}
