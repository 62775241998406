import Modal from 'react-modal'; // It can be any modal
import { SCREENS } from 'src/constants/strings';
import { Box, Text } from 'src/theme';
import theme from 'src/theme/theme';
import { MODALS, MODAL_TYPES } from 'src/@types/navigation';
import errorIcon from 'src/images/error_modal/alert.svg';
import ModalButton from './ModalButton';

export default function ApprovalModal({
  isOpen,
  onClose,
  title = SCREENS.NOTICE_MODAL_TITLE,
  subTitle,
  rightBtnText,
  leftBtnText,
  rightBtn,
  leftBtn,
  contentStyle,
}: MODALS[MODAL_TYPES.APPROVAL]): JSX.Element {
  return (
    <Modal
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: '#3737372E',
        },
        content: contentStyle,
      }}
      isOpen={isOpen}
      onRequestClose={onClose}>
      <Box px={1}>
        <Box display={'flex'} textAlign={'center'}>
          <Box pl={2} py={1}>
            <img src={errorIcon} alt="saveIcon" />
          </Box>
          <Text fontWeight={300} fontSize={18}>
            {title}
          </Text>
        </Box>
        <Text lineHeight={1.5} fontWeight={200} color={'grey7a7a7a'}>
          {subTitle}
        </Text>

        <Box display="flex" pt={3}>
          <Box>
            <ModalButton
              onClick={rightBtn || onClose}
              text={rightBtnText}
              type="transparent"
            />
          </Box>
          <Box px={1}>
            <ModalButton
              onClick={leftBtn || onClose}
              text={leftBtnText}
              type="blue"
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
