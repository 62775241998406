import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { QueryClientProvider } from 'react-query';
import { ModalProvider } from 'use-modal-hook';
import { useRef } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';

import theme, { Box } from 'src/theme';
import Router from './Router/Router';
import TopSnackBar from './components/TopSnackBar/TopSnackBar';
import configureStore from './store';
import { setNetwork } from './api/RequestHandler';
import getQueryClient from 'src/cache';

const App = (): JSX.Element => {
  const currentURL = window.location.href;
  if (currentURL.includes('app-staging')) {
    setNetwork('https://app-staging.tagbiz.io/api/v1');
  } else if (currentURL.includes('app')) {
    setNetwork('https://server.tagbiz.io/api/v1');
  } else {
    // setNetwork('https://server.tagbiz.io/api/v1');
    setNetwork('https://app-staging.tagbiz.io/api/v1');
    // setNetwork('http://or.local:8000/api/v1');
  }

  const store = useRef(configureStore()).current;
  const queryClient = getQueryClient();

  return (
    <Box height={'100%'}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Provider {...{ store }}>
            <ModalProvider>
              <TopSnackBar />
              <Router />
            </ModalProvider>
          </Provider>
          {!currentURL.includes('.tagbiz') && (
            <ReactQueryDevtools
              panelProps={{
                style: {
                  backgroundColor: 'blue2c3f58',
                  fontSize: 14,
                  color: 'white',
                },
              }}
              initialIsOpen={false}
            />
          )}
        </QueryClientProvider>
      </ThemeProvider>
    </Box>
  );
};

export default App;
